<!-- 精英版企业 -->
<template>
  <div class="elite_company">
    <div class="caozuo" v-if="show">
      <div class="screen">
        <div>
          <el-cascader
            class="width"
            v-model="diqu_value"
            placeholder="选择地区"
            :options="city_options"
            @change="city_change"
          ></el-cascader>
        </div>
        <div>
          <el-input
            placeholder="搜索公司名称"
            v-model="input_sousuo"
            class="width"
          ></el-input>
        </div>
        <div>
          <el-button
            icon="el-icon-search"
            title="搜索"
            class="width"
            @click="search"
          ></el-button>
        </div>
      </div>
      
      <div>
        <el-menu :default-active='default_active_index' mode='horizontal' @select="handleSelect">
          <el-menu-item index="0">全职</el-menu-item>
          <el-menu-item index="1">零工</el-menu-item>
        </el-menu>
      </div>
    </div>
    <transition name="el-zoom-in-center">
      <div v-show="show" class="content" v-loading="loading">
        <div
          class="item_position"
          v-for="(item, index) in company_data"
          :key="index"
          @click="item_click(item)"
        >
          <div class="title">
            <p :title="item.company_introduce">{{ item.company_name }}</p>
          </div>
          <div class="tags">
            <p>
              <span
                >{{ item.company_place_data.city_name }} -
                {{ item.company_place_data.district_name }}</span
              >
              <span
                >{{ item.company_people_min }} -
                {{ item.company_people_max }} 人</span
              >
              <span v-if="JSON.stringify(item.company_industry_data) != '{}'">{{
                item.company_industry_data.second_industry_name
              }}</span>
            </p>
          </div>
          <div class="bottom">
            <el-image
              v-if="item.sign_img"
              :src="item.sign_img"
              :preview-src-list="[item.sign_img]"
            ></el-image>
            <!-- <img v-if :src="item.sign_img" alt /> -->
            <div class="img" v-else>暂无头像</div>
            <div style="line-height: 20px; color: #5f5f5f">
              {{ item.sign_name }} · {{ item.sign_position }}
            </div>
          </div>
          <div class="company_logo">
            <img :src="item.company_logo_url" alt />
          </div>
        </div>
        <div v-if="company_data.length == 0" class="kong_company">暂无公司</div>
      </div>
    </transition>
    <div class="page" v-if="company_data.length >= 0 && show">
      <el-button
        size="mini"
        @click="goPages('next')"
        :disabled="company_data.length < 20"
      >
        下一页
        <!-- <i class="el-icon-arrow-right el-icon--right"></i> -->
      </el-button>
      <div>
        <p>第 {{ page + 1 }} 页</p>
      </div>
      <el-button size="mini" @click="goPages('prev')" :disabled="page + 1 <= 1">
        <!-- <i class="el-icon-arrow-left el-icon--left"></i> -->
        上一页
      </el-button>
    </div>
    <br />
    <el-dialog
      title="企业微信群二维码"
      :visible.sync="is_company_wx_qr"
      width="30%"
      :before-close="
        () => {
          is_company_wx_qr = false;
        }
      "
    >
      <div class="company_wx_qr">
        <img :src="company_wx_qr" alt="" />
        <p></p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { company_list, odd_company_list, show_client_place } from "@/api/eliteApi";
import CompanyItem from "./compontent/company_item";
import "element-ui/lib/theme-chalk/base.css";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    CompanyItem,
  },
  data() {
    //这里存放数据
    return {
      input_sousuo: "",
      diqu_value: [],
      city_options: [],
      company_data: [],
      loading: false,
      page: 0,
      total: 50,
      currentPage1: 1,
      show: true,
      is_search: true,
      company_wx_qr: "",
      is_company_wx_qr: false,
      
      default_active_index: "0", // 全职 / 零工
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 全职零工
    handleSelect(e) {
      if(e != this.default_active_index){
        this.default_active_index = e
        this.page = 0;
        this.search()
      }
    },
    // 缓存
    Interfacedata() {
      this.loading = true;
      this.page = Number(sessionStorage.getItem("company_page")) || this.page;
      if (sessionStorage.getItem("place_ip")) {
        let place_obj = {};
        place_obj = JSON.parse(sessionStorage.getItem("place_ip"));
        this.diqu_value = [
          place_obj.province_code + "",
          place_obj.city_code + "",
          place_obj.city_code + "",
        ];
      }
      let search_input = JSON.parse(sessionStorage.getItem("search_input"))
      if (search_input) {
        this.input_sousuo = search_input.input_sousuo || "";
        this.default_active_index = search_input.default_active_index || '0';
        this.diqu_value = search_input.diqu_value || [];
      }
      this.Interface()
    },
    // 判断全职还是零工
    Interface() {
      this.loading = true;
      if(this.default_active_index == 0) {
        this.init();
      }else {
        this.odd_company_list()
      }
    },
    // 零工列表
    odd_company_list() {
      let obj = {
        company_place_id: this.diqu_value[this.diqu_value.length - 1],
      };
      if (this.input_sousuo) {
        obj.company_name = this.input_sousuo;
      }
      console.log('this.diqu_value', this.diqu_value)
      odd_company_list({
        select_data: obj,
        page: (Number(sessionStorage.getItem("company_page")) || this.page)+1,
      }).then((res) => {
        console.log(res);
        if (!res.code) {
          this.company_data = res.data.company_list;
          this.loading = false;
        }
      });
    },

    //进入详情
    item_click(item) {
      sessionStorage.setItem("company_page", this.page);
      let { default_active_index } = this
      
      this.$router.push({
        path: default_active_index == 0 ? "/elite_company_details" : "/odd_jd_company_item",
        query: {
          id: item.user_detail_id,
        },
      });
    },
    //分页
    goPages(type) {
      sessionStorage.removeItem("company_page");
      if (type == "prev") {
        this.page < 0 ? (this.page = 0) : this.page--;
      } else {
        this.page++;
        console.log("page", this.page);
      }
      this.Interface();
    },

    //选择城市
    city_change(e) {
      if (!this.show) {
        this.$message.warning("请回到列表页面");
        return false;
      }
      // console.log(this.diqu_value)
      // this.diqu_value
      this.search();
    },
    //公司名称搜索
    search() {
      if (!this.show) {
        this.$message.warning("请回到列表页面");
        return false;
      }
      sessionStorage.setItem( "search_input", JSON.stringify({ 
        input_sousuo: this.input_sousuo, 
        diqu_value: this.diqu_value,
        default_active_index: this.default_active_index,
      }) );
      // sessionStorage.setItem("company_page", this.page);
      this.Interface();
    },
    //地区筛选列表参数   unlimited   区级地区是否有不限 0没不限 1有不限
    show_client_place() {
      show_client_place({
        unlimited: 1,
      }).then((res) => {
        if (!res.code) {
          this.city_options = res.data;
        }
        console.log(res);
      });
    },
    //公司列表
    init() {
      let obj = {
        company_place_id: this.diqu_value[this.diqu_value.length - 1],
        unlimited: "1", //unlimited为空，说明筛选 市-区。   不为空，说明筛选 市-不限
      };
      if (this.input_sousuo) {
        obj.company_name = this.input_sousuo;
      }
      company_list({
        select_data: obj,
        page: (Number(sessionStorage.getItem("company_page")) || this.page)+1,
      }).then((res) => {
        console.log(res);
        if (!res.code) {
          this.company_data = res.data.data;

          if (res.data.company_wx_qr) {
            this.company_wx_qr = res.data.company_wx_qr;
            this.setTime();
          }
          this.loading = false;
        }
      });
    },
    setTime() {
      setTimeout(() => {
        this;
      }, 5000);
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.show_client_place();
    this.Interfacedata();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style  scoped>
/* //@import url(); 引入公共css类 */
.elite_company {
  padding: 12px;
  background: #f6f6f8;
  min-height: 100vh;
  position: relative;
  box-sizing: border-box;
}
p {
  padding: 0;
  margin: 0;
}
/* //@import url(); 引入公共css类 */


.caozuo {
  /* width: calc(100% - 24px); */
  width: 100%;
  position: fixed;
  top: 62px;
  left: 0;
  /* height: 80px; */
  overflow: hidden;
  padding: 12px 0px;
  background: #fff;
  z-index: 10;
}
.caozuo > div {
  width: 1200px;
  margin: auto;
  overflow: hidden;
}
.screen{
  padding-top: 20px;
  display: flex;
}
.width {
  width: 90%;
  margin: auto;
}
.content {
  width: 1200px;
  margin: auto;
  margin-top: 200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.content::-webkit-scrollbar {
  width: 6px;
}

/* .item_position:first-child {
  position: relative;
  cursor: pointer;
  margin-top: 0px;
} */

.item_position {
  position: relative;
  cursor: pointer;
  margin: 8px 0;
  width: 45%;
  /* cursor: pointer; */
  /* box-shadow: rgb(222 190 150) -2px 7px 12px 3px; */
  box-shadow: 0 0 8px #ccc;
  background: #fff;
  border-radius: 4px;
  padding: 12px;
  cursor: pointer;
}

/* .item_position:nth-child(2n-1) {
  float: left;
  width: 554px;
  position: relative;
  cursor: pointer;
  margin: 8px 0;
  height: 120px;
}
.item_position:nth-child(2n) {
  float: right;
  width: 554px;
  position: relative;
  cursor: pointer;
  margin: 8px 0;
  height: 120px;
} */
.title {
  overflow: hidden;
  border-bottom: #e6e6e6 solid 1px;
  margin-bottom: 12px;
  font-weight: 600;
}
.title > p {
  font-size: 16px;
  /* font-weight: bold; */
  margin-bottom: 12px;
  color: #2d2d2d;
}

.bottom {
  overflow: hidden;
}
.bottom > .el-image {
  float: left;
  width: 20px;
  height: 20px;
  margin-right: 12px;
}
.bottom > div {
  float: left;
}
.tags {
  padding: 6px 0;
  color: #a7a7a7;
  margin-bottom: 20px;
}
.tags > p:last-child > span:first-child {
  padding-left: 0px;
}
.tags > p:last-child > span {
  font-size: 16px;
  padding: 3px 12px;
  margin: 0 6px;
  border-right: #ccc solid 1px;
  font-size: 14px;
}
.tags > p:last-child > span:last-child {
  border-right: none;
}
.xinzi_item {
  position: absolute;
  right: 40px;
  top: 20px;
  font-size: 26px;
  font-weight: bold;
  color: #dc0000;
}
.weChat {
  position: absolute;
  right: 40px;
  top: 64px;
  font-size: 16px;
  /* font-weight: bold; */
  background: #41b883;
  border-radius: 5px;
  padding: 3px 12px;
  color: #fff;
  cursor: pointer;
}
.diqu {
  overflow: hidden;
}
.diqu > div {
  float: left;
}
.with-cascader {
  width: 200px;
}
.input-with-select {
  width: 500px;
}
.img {
  line-height: 20px;
  font-size: 12px;
  color: rgb(175, 175, 175);
  margin-right: 12px;
}
.company_logo {
  position: absolute;
  top: 52px;
  right: 22px;
}
.company_logo > img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.page {
  width: 1200px;
  margin: auto;
  margin-top: 12px;
  text-align: right;
  overflow: hidden;
  font-size: 15px;
}
.page > button,
.page > div {
  float: right;
  margin-right: 28px;
}
.page > div {
  line-height: 28px;
}
.kong_company {
  line-height: 500px;
  text-align: center;
  color: #ccc;
  font-size: 16px;
  background: #fff;
  width: 100%;
}

/* 详情 */
.content_item {
  width: 1200px;
  margin: auto;
  margin-top: 58px;
  min-height: 500px;
  overflow: hidden;
  padding: 12px;
  background: #fff;
}
.company_wx_qr {
  width: 100%;
}
</style>